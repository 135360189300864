import React, { useEffect } from 'react'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

const report = '/docs/Cliente-versao2.pdf'

const PrivacyClientsVersion2PDF = () => {
  useEffect(() => {
    if (window) {
      window.location = report
    }
  })
  return (
    <Layout>
      <SEO
        description="Políticas de privacidade de clientes PDF válido de 17/07/2018 até 14/09/2020"
        title="Políticas de privacidade de clientes PDF válido de 17/07/2018 até 14/09/2020"
      />
    </Layout>
  )
}

export default PrivacyClientsVersion2PDF
